import Cookies from 'js-cookie';
import { clearErrors, setAlert, setSuccess } from './alertActions';

// API
import { createAccount, getUser, login, logout, verifyMultiFactorAuthentication } from '../../api';

// Constants
import { USER_TYPES } from '../../constants';
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const SOFT_AUTHENTICATED = 'SOFT_AUTHENTICATED';
export const FULLY_AUTHENTICATED = 'FULLY_AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const SET_SKELETON_USER = 'SET_SKELETON_USER';
export const CLEAR_SKELETON_USER = 'CLEAR_SKELETON_USER';

const SOFT_COOKIE_NAME = process.env.REACT_APP_SOFT_COOKIE_NAME;
const FULL_COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

// Actions
export const startLoading = () => ({
  type: IS_LOADING
});

export const stopLoading = () => ({
  type: IS_NOT_LOADING
});

export const softAuthenticated = (userType = USER_TYPES.GUEST, twoFactorAuthSetupRequired = false, hasBusiness = false) => ({
  type: SOFT_AUTHENTICATED,
  userType,
  twoFactorAuthSetupRequired,
  hasBusiness
});

export const fullyAuthenticated = (userType = USER_TYPES.GUEST, hasBusiness = false) => ({
  type: FULLY_AUTHENTICATED,
  userType,
  hasBusiness
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED
});

export const setSkeletonUser = (userID, userName, userType = USER_TYPES.GUEST, hasBusiness = false) => ({
  type: SET_SKELETON_USER,
  userID,
  userName,
  userType: userType?.toUpperCase(),
  hasBusiness
});

export const clearSkeletonUser = () => ({
  type: CLEAR_SKELETON_USER
});

/* ----- SETUP ACCOUNT ----- */

export const createUserAccount = (dispatch, userObj, token) => {
  dispatch(startLoading());

  // Attempt to create an account
  createAccount(userObj, token).then(user => {
    setSuccess(dispatch, 'Successfully created account.');
    dispatch(softAuthenticated(user.getUserType(), user.getTwoFactorSetupRequired(), user.getBusinessID() != null));
  }).catch(error => {
    setAlert({ dispatch, msg: error });
    dispatch(notAuthenticated());
  });
};

/* ----- AUTHENTICATE ----- */

export const softAuthenticateUser = (dispatch, email = null, password = null) => {
  dispatch(startLoading());

  login(email, password).then(user => {
    dispatch(clearErrors());
    dispatch(softAuthenticated(user.getUserType(), user.getTwoFactorSetupRequired(), user.getBusinessID() != null));
  }).catch(error => {
    setAlert({ dispatch, msg: error });
    dispatch(notAuthenticated());
  });
};

export const fullyAuthenticateUser = (dispatch, token = null) => {
  dispatch(startLoading());

  verifyMultiFactorAuthentication(token).then(user => {
    dispatch(clearErrors());
    dispatch(fullyAuthenticated(user.getUserType(), user.getBusinessID() != null));
  }).catch(error => {
    setAlert({ dispatch, msg: error });
    dispatch(stopLoading());
  });
};

export const checkAuthentication = (dispatch, isSoftAuthenticated, isFullyAuthenticated) => {
  dispatch(startLoading());

  // If we falsely have the user logged in, log them out and clear all cookies.
  if ((Cookies.get(SOFT_COOKIE_NAME) == null && isSoftAuthenticated) || (Cookies.get(FULL_COOKIE_NAME) == null && isFullyAuthenticated)) {
    logoutUser(dispatch);
  }

  else if (Cookies.get(SOFT_COOKIE_NAME) != null && !isSoftAuthenticated) {
    // If both cookies are there, fully authenticate
    if (Cookies.get(FULL_COOKIE_NAME) != null && !isFullyAuthenticated) {
      getUser().then(user => {
        dispatch(fullyAuthenticated(user?.getUserType(), user?.getBusinessID() != null));
      }).catch(error => {
        console.log(error);
        dispatch(fullyAuthenticated(false));
      });
    }

    // If only the soft cookie is there, fully logout the user to prevent a weird limbo state
    else if (Cookies.get(FULL_COOKIE_NAME) == null) logoutUser(dispatch);
  }

  else dispatch(stopLoading());
};

export const logoutUser = (dispatch) => {
  // Revoke tokens and unauthenticate in redux state
  if (Cookies.get(SOFT_COOKIE_NAME) != null && Cookies.get(FULL_COOKIE_NAME) != null) {
    logout().then(() => {
      setSuccess('Successfully logged out.');
      clearCookies(dispatch);
    }).catch(() => {
      clearCookies(dispatch);
    });
  }
  else clearCookies(dispatch);
};

export const clearCookies = (dispatch) => {
  Cookies.remove(SOFT_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  Cookies.remove(FULL_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  dispatch(notAuthenticated());
};
