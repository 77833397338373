import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

class MapWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: this.props.defaultCenter,
      zoom: this.props.defaultZoom ?? 11
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultCenter?.lng !== this.props.defaultCenter?.lng || prevProps.defaultCenter?.lat !== this.props.defaultCenter?.lat) {
      this.setState({ center: this.props.defaultCenter });
    }
    if (prevProps.defaultZoom !== this.props.defaultZoom) {
      this.setState({ zoom: this.props.defaultZoom });
    }
  }

  render() {
    return (
      <div style={{ height: this.props.height, width: this.props.width, ...this.props.style }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDSDsTwCHJzumvHSIUL0aHRqHurc6QAAW4' }}
          center={this.state.center}
          zoom={this.state.zoom ?? 11}
          onChange={({ center, zoom }) => this.setState({ center, zoom })}
          yesIWantToUseGoogleMapApiInternals={this.props.yesIWantToUseGoogleMapApiInternals ?? false}
          onGoogleApiLoaded={this.props.onGoogleApiLoaded ?? null}
          options={{
            scaleControl: true
          }}
        >
          {this.props.children}
        </GoogleMapReact>
      </div>
    );
  }
}

MapWidget.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  defaultCenter: PropTypes.object.isRequired,
  defaultZoom: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
  yesIWantToUseGoogleMapApiInternals: PropTypes.bool,
  onGoogleApiLoaded: PropTypes.func
};

export default MapWidget;
