import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import Table from '../../../components/Table';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyIcon from '@mui/icons-material/Key';
import TrashIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

// Redux
import { clearErrors, setError, setSuccess } from '../../../alerts';
import { setSkeletonUser } from '../../../redux/actions/authActions';
import { connect } from 'react-redux';

// API
import { listAdminInvitations, listGuestInvitations, listUsers, resendInvitation, revokeInvitation } from '../../../api/admin';
import { Button } from '@lexcelon/react-util';

// Constants
const COLUMNS = ({ onSkeletonKeyPress }) => ([
  {
    title: 'View',
    render: rowData => (
      <IconButton component={Link} to={'/admin/users/' + rowData?.id}><VisibilityIcon /></IconButton>
    )
  },
  {
    title: 'Skeleton Key',
    render: rowData => (
      <IconButton onClick={() => onSkeletonKeyPress(rowData.getID(), rowData.getName(), rowData.getRole()?.getName(), rowData.getBusinessID() != null)}><KeyIcon /></IconButton>
    )
  },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Email', field: 'email' },
  { title: 'Phone Number', field: 'phoneNumber' },
  {
    title: 'Last Logged In',
    render: (rowData) => rowData.lastLoginTimestamp?.toLocaleString(DateTime.DATETIME_SHORT),
    customSort: (a, b) => {
      const aTimestamp = a.getLastLoginTimestamp();
      const bTimestamp = b.getLastLoginTimestamp();
      if (aTimestamp == null || bTimestamp == null) {
        if (aTimestamp == null && bTimestamp == null) return 0;
        else if (aTimestamp == null) return 1;
        return -1;
      }
      return aTimestamp.diff(bTimestamp, 'seconds').get('seconds');
    }
  },
  {
    title: 'Is Active',
    field: 'isActive',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term.toLowerCase() === 'active') || (!rowData.isActive && term.toLowerCase() === 'inactive')
  },
  { title: 'Role', field: 'role.name' },
  { title: 'Business', field: 'business.name' },
  { title: 'Associated Business', field: 'associatedWithBusiness.name' }
]);

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      guestInvitations: [],
      adminInvitations: [],
      isRevokingInvitationForId: null,
      isResendingInvitationForId: null,
      redirect: false
    };
  }

  componentDidMount() {
    listUsers().then(users => {
      this.setState({ users });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve users.');
    });

    listGuestInvitations().then(guestInvitations => {
      this.setState({ guestInvitations });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve guest invitations.');
    });

    listAdminInvitations().then(adminInvitations => {
      this.setState({ adminInvitations });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve admin invitations.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onSkeletonKeyPress = (userID, userName, userType, hasBusiness) => {
    this.props.onSkeletonKeyPress(userID, userName, userType, hasBusiness);
    this.setState({ redirect: true });
  }

  revokeInvitation = (userInvitation) => {
    this.setState({ isRevokingInvitationForId: userInvitation.getID() });
    revokeInvitation(userInvitation.getID()).then(() => {
      setSuccess('Successfully revoked guest invitation.');
      this.setState({ isRevokingInvitationForId: null });
      this.componentDidMount();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to revoke invitation.');
      this.setState({ isRevokingInvitationForId: null });
    });
  }

  resendInvitation = (userInvitation) => {
    this.setState({ isResendingInvitationForId: userInvitation.getID() });
    resendInvitation(userInvitation.getID()).then(() => {
      setSuccess('Successfully resent guest invitation.');
      this.setState({ isResendingInvitationForId: null });
      this.componentDidMount();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to resend invitation.');
      this.setState({ isResendingInvitationForId: null });
    });
  }

  render() {
    return this.state.redirect ? <Redirect to='/account' /> : (
      <div style={{ margin: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Users</Typography>

        <Table
          title='All Users'
          data={this.state.users}
          columns={COLUMNS({ onSkeletonKeyPress: this.onSkeletonKeyPress })}
          options={{ pageSize: 20 }}
        />

        <Typography variant='h2' style={{ textAlign: 'center', margin: '1em' }}>Admin/Guest Invitations</Typography>
        <Table 
          title={
            <Button component={Link} to='/admin/users/invite' variant='contained' color='primary' style={{ width: 200 }}>Invite Admin/Guest</Button>
          }
          data={[...this.state.adminInvitations, ...this.state.guestInvitations]}
          columns={[
            { title: 'Email', field: 'email' },
            { title: 'Role', render: rowData => rowData.getRole()?.getName() },
            { title: 'Associated With Business', render: rowData => rowData.getAssociatedWithBusiness()?.getName() },
            { title: 'Resend', render: rowData => (
              <IconButton onClick={() => this.resendInvitation(rowData)} style={{ marginTop: 10 }} disabled={this.state.isResendingInvitationForId || this.state.isRevokingInvitationForId}>
                <RefreshIcon />
                {this.state.isResendingInvitationForId && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'grey' }} />}
              </IconButton>
            ) },
            { title: 'Revoke', render: rowData => (
              <IconButton onClick={() => this.revokeInvitation(rowData)} style={{ marginTop: 10 }} disabled={this.state.isResendingInvitationForId || this.state.isRevokingInvitationForId}>
                <TrashIcon />
                {this.state.isRevokingInvitationForId && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'grey' }} />}
              </IconButton>
            ) },
          ]}
          options={{ pageSize: 5 }}
        />
      </div>
    );
  }
}

Users.propTypes = {
  onSkeletonKeyPress: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  onSkeletonKeyPress: (userID, userName, userType, hasBusiness) => dispatch(setSkeletonUser(userID, userName, userType, hasBusiness))
});

export default connect(null, mapDispatchToProps)(Users);
