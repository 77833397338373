import { DateTime } from 'luxon';

export default class Video {
  static thaw(videoObj) {
    if (videoObj == null) return null;
    const video = new Video();
    if (videoObj.clipNumber != null) video.setClipNumber(videoObj.clipNumber);
    if (videoObj.date != null) video.setDate(DateTime.fromISO(videoObj.date));
    if (videoObj.url != null) video.setUrl(videoObj.url);
    if (videoObj.key != null) video.setKey(videoObj.key);
    if (videoObj.fromGlacier != null) video.setFromGlacier(videoObj.fromGlacier);
    return video;
  }

  static thawList(videoObjList) {
    if (videoObjList == null) return [];
    var videos = [];
    for (var i = 0; i < videoObjList.length; i++) {
      videos.push(Video.thaw(videoObjList[i]));
    }
    return videos;
  }

  constructor() {
    this.setClipNumber(null);
    this.setDate(null);
    this.setUrl(null);
    this.setKey(null);
    this.setFromGlacier(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setClipNumber(clipNumber) {
    this.clipNumber = clipNumber;
  }

  setDate(date) {
    this.date = date;
  }

  setUrl(url) {
    this.url = url;
  }

  setKey(key) {
    this.key = key;
  }

  setFromGlacier(fromGlacier) {
    this.fromGlacier = fromGlacier;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getClipNumber() {
    return this.clipNumber;
  }

  getDate() {
    return this.date;
  }

  getUrl() {
    return this.url;
  }

  getKey() {
    return this.key;
  }

  getFromGlacier() {
    return this.fromGlacier;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}
