import axiosWithCredentials from '../';

import { Camera, Boat, Business, SatelliteMessage, Trip, TripProject, User, SatelliteLogMessage, OutgoingSatelliteCommand, CameraConfigurationFile, UserInvitation, GuestTripPermission, Role } from '../../lib';

export function listUsers() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/user').then(res => {
      resolve(User.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBusinesses() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/business').then(res => {
      resolve(Business.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getUser(userID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/user/${userID}`).then(res => {
      resolve(User.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBusiness(businessID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/business/${businessID}`).then(res => {
      resolve(Business.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateUser(userID, userObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch(`/admin/user/${userID}`, userObj).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateBusiness(businessID, businessObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch(`/admin/business/${businessID}`, businessObj).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleUserActivation(userID, isActive) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/user/${userID}/active`, { isActive }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleBusinessActivation(businessID, isActive) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/business/${businessID}/active`, { isActive }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function resetUser2FA(userID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/user/${userID}/reset-two-factor`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function inviteBusinessUser(businessID, email) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/business/${businessID}/invitation`, { email }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function revokeUserInvitation(businessID, invitationID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/business/${businessID}/invitation/${invitationID}/revoke`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendUserInvitation(businessID, invitationID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/business/${businessID}/invitation/${invitationID}/resend`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createBusiness(businessObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/business/', businessObj).then(business => {
      resolve(Business.thaw(business));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createCamera({ name, awsIdentifier, rockBlockImei, configurationFiles, boatID }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/camera/', {
      name,
      awsIdentifier,
      rockBlockImei,
      configurationFiles,
      boatId: boatID
    }).then(camera => {
      resolve(Camera.thaw(camera));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateCamera(cameraID, cameraObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch(`/admin/camera/${cameraID}`, cameraObj).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getCamera(cameraID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/camera/${cameraID}`).then(camera => {
      resolve(Camera.thaw(camera));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleCameraActivation(cameraID, isActive) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/camera/${cameraID}/active`, { isActive }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function createBoat({ name, awsIdentifier, businessID }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/boat/', {
      name,
      awsIdentifier,
      businessId: businessID
    }).then(boat => {
      resolve(Boat.thaw(boat));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateBoat(boatID, boatObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch(`/admin/boat/${boatID}`, boatObj).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBoat(boatID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/boat/${boatID}`).then(boat => {
      resolve(Boat.thaw(boat));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleBoatActivation(boatID, isActive) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/boat/${boatID}/active`, { isActive }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleTripActivation(tripId, isActive) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/trip/${tripId}/active`, { isActive }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleTripVisibleToUser(tripId, visibleToUser) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/trip/${tripId}/visible`, { visibleToUser }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function resetCamera(cameraID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/camera/${cameraID}/reset`).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTrips(boatID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/boat/${boatID}/trip`).then(res => {
      resolve(Trip.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function checkTripProject(boatID, projectID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/boat/${boatID}/trip-project/check?projectId=${projectID}`).then(res => {
      resolve(TripProject.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTrip(tripID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/trip/${tripID}`).then(res => {
      resolve(Trip.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createTrip(boatID, startTimestamp, stopTimestamp, convertToKg, projects, tripCameras) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/boat/${boatID}/trip`, {
      startTimestamp: startTimestamp?.toMillis(),
      stopTimestamp: stopTimestamp?.toMillis(),
      convertToKg: convertToKg,
      projects: TripProject.freezeList(projects),
      tripCameras
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateTrip(tripID, startTimestamp, stopTimestamp, convertToKg, projects, tripCameras) {
  return new Promise(function(resolve, reject) {

    // Map necessary project information
    let updateProjects = TripProject.freezeList(projects)?.map(project => {
      return {
        id: project.id,
        projectId: project.projectId,
        cameraId: project.cameraId,
        s3Path: project.s3Path
      };
    });

    axiosWithCredentials.patch(`/admin/trip/${tripID}`, {
      startTimestamp: startTimestamp?.toMillis(),
      stopTimestamp: stopTimestamp?.toMillis(),
      convertToKg: convertToKg,
      projects: updateProjects,
      tripCameras
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function clearTrip(tripId) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/admin/trip/${tripId}/clear`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateCameraConfiguration(cameraID, configurationObject) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/camera/${cameraID}/configuration-update/`, configurationObject).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getLatestSatelliteMessageForCamera(cameraID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/camera/${cameraID}/satellite/latest`).then(res => {
      resolve(SatelliteMessage.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * @param {number} [cameraId] The id of the camera to filter satellite logs by.
 * @returns {Promise<SatelliteLogMessage[]>} A list of logs from the server.
 */
export function getLatestSatelliteLogMessages(cameraId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/satellite/logs' + (cameraId != null ? `?cameraId=${cameraId}` : '')).then(res => {
      resolve(SatelliteLogMessage.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * @param {number} [cameraId] The id of the camera to filter satellite commands by.
 * @returns {Promise<OutgoingSatelliteCommand[]>} A list of sent commands from the server.
 */
export function getSatelliteOutgoingCommands(cameraId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/satellite/outgoing' + (cameraId != null ? `?cameraId=${cameraId}` : '')).then(res => {
      resolve(OutgoingSatelliteCommand.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * @param {number} [cameraId] The id of the camera to filter satellite commands by.
 * @returns {Promise<CameraConfigurationFile[]>} A list of sent commands from the server.
 */
export function listCameraConfigurations(cameraId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/camera/${cameraId}/config-files/`).then((configurations) => {
      resolve(CameraConfigurationFile.thawList(configurations));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUserRoles() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/user/role').then(res => {
      resolve(Role.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function inviteUser({ email, roleId, associatedWithBusinessId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/user/invitation', { email, roleId, associatedWithBusinessId }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGuestInvitations() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/user/invitation/guest').then(res => {
      resolve(UserInvitation.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listAdminInvitations() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/user/invitation/admin').then(res => {
      resolve(UserInvitation.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendInvitation(userInvitationId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/user/invitation/${userInvitationId}/resend`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function revokeInvitation(userInvitationId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/user/invitation/${userInvitationId}/revoke`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTripsForBusiness(businessId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/business/${businessId}/trip`).then(res => {
      resolve(Trip.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGuestTripPermissionsForTrip(tripId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/trip/${tripId}/guest-permission`).then(res => {
      resolve(GuestTripPermission.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createGuestTripPermission({ tripId, guestId = undefined, guestInvitationId = undefined, fishingPerformance, complianceReports, videos, images }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/admin/trip/${tripId}/guest-permission`, {
      guestId,
      guestInvitationId,
      fishingPerformance,
      complianceReports,
      videos,
      images
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateGuestTripPermission({ guestTripPermissionId, fishingPerformance, complianceReports, videos, images }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch(`/admin/guest-trip-permission/${guestTripPermissionId}`, {
      fishingPerformance,
      complianceReports,
      videos,
      images
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteGuestTripPermission(guestTripPermissionId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.delete(`/admin/guest-trip-permission/${guestTripPermissionId}`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getGuestTripPermission(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get(`/admin/guest-trip-permission/${id}`).then(res => {
      resolve(GuestTripPermission.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}