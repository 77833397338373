import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, TextField, Typography, Autocomplete } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// API
import { inviteUser, listBusinesses, listUserRoles } from '../../../../api/admin';

class InviteGuest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businesses: [],
      business: null,
      email: '',
      isLoadingBusinesses: true,
      isLoading: false,
      redirectToUsers: false,
      type: null
    };
  }

  componentDidMount() {
    this.setState({ isLoadingBusinesses: true });
    listBusinesses().then(businesses => {
      this.setState({
        businesses,
        isLoadingBusinesses: false
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve businesses.');
      this.setState({ isLoadingBusinesses: false });
    });

    listUserRoles().then(roles => {
      this.setState({ roles });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve user roles.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  inviteGuest = (e) => {
    e.preventDefault();

    const { email, business, role } = this.state;

    this.setState({ isLoading: true });
    inviteUser({ email, associatedWithBusinessId: business?.id ?? null, roleId: role?.getID() }).then(() => {
      setSuccess('Successfully invited user.');
      this.setState({ isLoading: false, redirectToUsers: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to invite user.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToUsers ? <Redirect to='/admin/users/' /> : (
      <Container style={{ marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1'>Invite Guest User</Typography>
          {this.state.isLoadingBusinesses && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        <form onSubmit={this.inviteGuest}>
          {/* User Role */}
          <Autocomplete
            disablePortal
            value={this.state.role}
            getOptionLabel={option => option.getName()}
            options={this.state.roles?.filter(role => role.getID() !== 1) ?? []}
            disabled={this.state.isLoading}
            onChange={(_, role) => this.setState({ role })}
            style={{ width: '100%', marginBottom: '10px' }}
            required
            renderInput={(params) => <TextField {...params} variant='filled' required label='User Role' />}
          />

          {/* Email Input */}
          <TextField
            required
            type='email'
            error={this.state.formError && this.state.email === ''}
            name='email'
            label='User Email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
          />

          {/* Business Association */}
          {this.state.role?.getID() === 3 &&
          <Autocomplete
            disablePortal
            value={this.state.business}
            getOptionLabel={option => option.getName()}
            options={this.state.businesses ?? []}
            disabled={this.state.isLoading || this.state.isLoadingBusinesses}
            onChange={(_, business) => this.setState({ business })}
            style={{ width: '100%', marginBottom: '10px' }}
            renderInput={(params) => <TextField {...params} variant='filled' label='Associated Business' />}
          />}

          <Button style={{ marginTop: 20 }} type='submit' disabled={this.state.isLoading}>
            Invite
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </form>
      </Container>
    );
  }
}

InviteGuest.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(InviteGuest);
