import { DateTime } from 'luxon';
import { Business, Role } from './';

export default class UserInvitation {
  static thaw(userInvitationObj) {
    if (userInvitationObj == null) return null;
    const userInvitation = new UserInvitation();
    if (userInvitationObj.id != null) userInvitation.setID(userInvitationObj.id);
    if (userInvitationObj.isValid != null) userInvitation.setIsValid(userInvitationObj.isValid == null ? null : (userInvitationObj.isValid ? true : false));
    if (userInvitationObj.email != null) userInvitation.setEmail(userInvitationObj.email);
    if (userInvitationObj.acceptedTimestmap != null) userInvitation.setAcceptedTimestmap(DateTime.fromISO(userInvitationObj.acceptedTimestmap));
    if (userInvitationObj.businessId != null) userInvitation.setBusinessID(userInvitationObj.businessId);
    if (userInvitationObj.associatedWithBusinessId != null) userInvitation.setAssociatedWithBusinessID(userInvitationObj.associatedWithBusinessId);
    if (userInvitationObj.business != null) userInvitation.setBusiness(Business.thaw(userInvitationObj.business));
    if (userInvitationObj.associatedWithBusiness != null) userInvitation.setAssociatedWithBusiness(Business.thaw(userInvitationObj.associatedWithBusiness));
    if (userInvitationObj.roleId != null) userInvitation.setRoleID(userInvitationObj.roleId);
    if (userInvitationObj.role != null) userInvitation.setRole(Role.thaw(userInvitationObj.role));
    if (userInvitationObj.createdAt != null) userInvitation.setCreatedAt(DateTime.fromISO(userInvitationObj.createdAt));
    return userInvitation;
  }

  static thawList(userInvitationObjList) {
    if (userInvitationObjList == null) return [];
    var userInvitations = [];
    for (var i = 0; i < userInvitationObjList.length; i++) {
      userInvitations.push(UserInvitation.thaw(userInvitationObjList[i]));
    }
    return userInvitations;
  }

  static freeze(userInvitation) {
    var userInvitationObj = {};
    if (userInvitation.getID() != null) userInvitationObj.id = userInvitation.getID();
    if (userInvitation.getIsValid() != null) userInvitationObj.isValid = userInvitation.getIsValid() == null ? null : (userInvitation.getIsValid() ? 1 : 0);
    if (userInvitation.getEmail() != null) userInvitationObj.email = userInvitation.getEmail();
    if (userInvitation.getAcceptedTimestmap() != null) userInvitationObj.acceptedTimestmap = userInvitation.getAcceptedTimestmap();
    if (userInvitation.getBusinessID() != null) userInvitationObj.businessId = userInvitation.getBusinessID();
    if (userInvitation.getAssociatedWithBusinessID() != null) userInvitationObj.associatedWithBusinessId = userInvitation.getAssociatedWithBusinessID();
    if (userInvitation.getBusiness() != null) userInvitationObj.business = Business.freeze(userInvitation.getBusiness());
    if (userInvitation.getAssociatedWithBusiness() != null) userInvitationObj.associatedWithBusiness = Business.freeze(userInvitation.getAssociatedWithBusiness());
    if (userInvitation.getRoleID() != null) userInvitationObj.roleId = userInvitation.getRoleID();
    if (userInvitation.getRole() != null) userInvitationObj.role = Role.freeze(userInvitation.getRole());
    if (userInvitation.getCreatedAt() != null) userInvitationObj.createdAt = userInvitation.getCreatedAt();
    return userInvitationObj;
  }

  static freezeList(userInvitations) {
    if (userInvitations == null) return [];
    var userInvitationObjList = [];
    for (var i = 0; i < userInvitations.length; i++) {
      userInvitationObjList.push(UserInvitation.freeze(userInvitations[i]));
    }
    return userInvitationObjList;
  }

  constructor() {
    this.setID(null);
    this.setIsValid(null);
    this.setEmail(null);
    this.setAcceptedTimestmap(null);
    this.setBusinessID(null);
    this.setAssociatedWithBusinessID(null);
    this.setBusiness(null);
    this.setAssociatedWithBusiness(null);
    this.setRoleID(null);
    this.setRole(null);
    this.setCreatedAt(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setIsValid(isValid) {
    this.isValid = isValid;
  }

  setEmail(email) {
    this.email = email;
  }

  setAcceptedTimestmap(acceptedTimestmap) {
    this.acceptedTimestmap = acceptedTimestmap;
  }

  setBusinessID(businessID) {
    this.businessID = businessID;
  }

  setAssociatedWithBusinessID(associatedWithBusinessID) {
    this.associatedWithBusinessID = associatedWithBusinessID;
  }

  setBusiness(business) {
    this.business = business;
  }

  setAssociatedWithBusiness(associatedWithBusiness) {
    this.associatedWithBusiness = associatedWithBusiness;
  }

  setRoleID(roleID) {
    this.roleID = roleID;
  }

  setRole(role) {
    this.role = role;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getIsValid() {
    return this.isValid;
  }

  getEmail() {
    return this.email;
  }

  getAcceptedTimestmap() {
    return this.acceptedTimestmap;
  }

  getBusinessID() {
    return this.businessID;
  }

  getAssociatedWithBusinessID() {
    return this.associatedWithBusinessID;
  }

  getBusiness() {
    return this.business;
  }

  getAssociatedWithBusiness() {
    return this.associatedWithBusiness;
  }

  getRoleID() {
    return this.roleID;
  }

  getRole() {
    return this.role;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}
